<template>
    <div class="shop_main">
        <div class="shop_search_menu" style="height: 60px; justify-content: space-between">
            <div style="display: flex; flex-direction: row; align-items: center">
                <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
                <i class="el-icon-arrow-right"></i>
                <span class="on">商城公告</span>
            </div>

<!--            <span style="font-size: 14px; line-height: 20px; color: #5DAD03; cursor: pointer" @click="toRead">全部已读</span>-->
        </div>

        <div class="shop_help" style="padding: 30px 96px 42px">
            <div class="content" v-loading="isRequestLoading" style="min-height: 308px; padding: 0; margin: 0; border: none">
                <ul>
                    <li v-for="(item, index) in listData" :key="index" style="border-bottom: 1px dashed #ccc; padding: 0 12px">
                        <a href="javascript:void(0)" @click="$router.push({path: '/shop/notice/detail', query: {id: item.id}})" style="align-items: center">
                            <div class="title flexRow" style="align-items: center; line-height: 16px; display: flex">
<!--                                <div style="width: 22px; height: 16px" v-if="item.isRead"></div><div style="width: 4px; height: 4px; background: #5DAD03; border-radius: 50%; display: inline-block; margin-right: 18px" v-else></div>-->
                                {{item.title}}
                            </div>
                            <span v-if="item.createAt" class="time">{{item.createAt.split('T')[0]}}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange"/>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {getShopNotice, postShopNoticeRead} from '../../../api/shop/notice'
  import {consumerPhone} from '../../../utils/config'
  const pagination = () => import('../../../components/pagination/shop')
  export default {
    name: "shopNotice",
    data () {
      return {
        listQuery: {
          page: 1,
          pageSize: 12
        },
        pages: {
          total: 0,
          pages: 0
        },
        isRequestLoading: false,
        listData: [],
        consumerPhone: consumerPhone
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true;
        getShopNotice(this.listQuery).then(res => {
          this.isRequestLoading = false;
          if (res.code === 0) {
            this.listData = res.data.records;
            this.pages.total = res.data.total;
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current;
        this.listQuery.pageSize = e.size;
        this.getList()
      },
      toRead () {
        this.$confirm('此操作将设置公告为全部已读, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          postShopNoticeRead().then(res => {
            if (res.code === 0) {
              this.$message({
                message: '全部已读成功',
                type: 'success',
                showClose: true,
              });
              this.getList();
            }
          })
        }).catch(() => {});
      }
    },
    components: {
      pagination
    }
  }
</script>
